.item {
  border: 1px solid var(--accent);
  border-radius: 8px;
  margin: 8px 0;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  line-height: normal;
}
.item:hover {
  border: 1px solid var(--iris10);
  background: var(--iris05);
}

.item .title {
  font-size: 16px;
  font-weight: 500;
}

.item .subTitle {
  color: var(--text-secondary);
  font-size: 14px;
  margin-top: 4px;
}
