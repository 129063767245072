.btn {
  background-color: var(--iris);
  border: 1px solid var(--iris);
  color: #fff;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 150ms ease;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  line-height: normal;
  font-family: Arial;
}
.btn:hover {
  opacity: 0.8;
}
.btn svg,
.btn img {
  max-height: 14px;
}

.btn.inline {
  color: var(--iris);
  background-color: transparent;
  padding: 0;
  display: inline-block;
  border: none;
}

.btn.secondary {
  background: var(--light-iris);
  color: var(--iris);
}
.btn.secondary:hover {
  background: var(--light-iris-hover);
}

.btn:disabled {
  opacity: 0.3;
}

.btn-icon {
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-icon.text {
  min-width: 32px;
  width: auto;
}
.btn-icon.error {
  color: var(--error);
}
.btn-icon img {
  width: 24px;
  height: 24px;
}

.btn-icon:hover {
  opacity: 0.7;
}

.btn-icon:disabled {
  opacity: 0.3;
}
