table.main {
  width: 100%;
  margin-top: 16px;
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px var(--accent);
  font-size: 14px;
  line-height: normal;
}

table.main tbody tr {
  cursor: pointer;
}
table.main tbody tr:hover {
  background-color: var(--accent);
}

table.main tbody tr.error-tr {
  background-color: var(--error-bg);
}

table.main tbody tr.error-deleted-tr {
  background-color: var(--iris05);
}

table.main tbody tr.deleted-tr {
  opacity: 0.6;
}

table.main tr a {
  text-decoration: none;
  font-weight: 600;
  color: var(--iris);
}
table.main tr td,
table.main tr th {
  border: 1px solid var(--accent);
  padding: 16px;
  text-align: left;
  word-break: keep-all;
  white-space: nowrap;
}
