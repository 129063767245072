.emptyScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100%;
}

.emptyScreen img {
  width: 70%;
  max-width: 300px;
}

.emptyScreen p {
  font-size: 16px;
  color: var(--text-secondary);
}
