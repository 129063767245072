.header-menu {
  --btn-active: #f8f5ff;
}
.header-menu .items {
  display: flex;
  gap: 8px;
  align-items: center;
}
.header-menu .items a {
  text-decoration: none;
}

.header-menu .items .item {
  border-radius: 100px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.7;
  color: var(--text);
}
.header-menu .items .item.active {
  background: var(--btn-active);
  opacity: 1;
}

.header-menu .items .item.is-icon {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
}
.header-menu .items .item img {
  max-width: 24px;
  max-height: 24px;
}
