.group-districts {
  border: 1px solid var(--accent);
  border-radius: 8px;
}
.group-districts .group-districts-items {
  /* display: block; */
  max-height: 900px;
  overflow: auto;
}

.group-districts .row-district {
  display: grid;
  height: 52px;
  grid-template-columns: auto 100px 100px;
  align-items: center;
  padding: 0 0 0 16px;
}

.group-districts .row-district + .row-district {
  border-top: 1px solid var(--accent);
}

.group-districts .row-district-title {
  font-size: 16px;
  font-weight: 600;
}

.group-districts .row-district-info {
  font-size: 14px;
  font-weight: 500;
}

.group-districts .row-district-edit {
  border-left: 1px solid var(--iris10);
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.group-districts input.add-new-district {
  border: 1px solid var(--iris10);
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--text-secondary);
  width: 300px;
}
.group-districts input.add-new-district:focus {
  outline-color: var(--iris);
}

.district-sidebar-content {
  margin: 8px 0 24px;
  flex-grow: 1;
  border-bottom: 1px solid var(--accent);
}

.district-description-text {
  color: var(--text-secondary);
}

.districts-add {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

