.title {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  margin-bottom: 20px;
  gap: 16px;
  align-items: center;
}

.title a {
  height: 24px;
}

.projectTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}
.projectTitle span {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary);
  display: block;
}

.projectSub {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
}
.projectSub span {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary);
  display: block;
}

.pill {
  background: rgba(236, 241, 244, 1);
  padding: 6px 16px;
  border-radius: 20px;

  color: rgba(140, 140, 161, 1);
  font-size: 14px;
  font-weight: 600;
}

.blocks {
  margin: 20px 0;
  display: grid;
  gap: 8px;
  grid-template-columns: 160px 160px 160px;
}

.taskBlock {
  padding: 24px 16px;
  border: 1px solid rgba(236, 241, 244, 1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.taskBlock.errorBlock .taskRowTitle {
  color: var(--error);
}

.taskRowTitle {
  font-size: 20px;
  font-weight: 500;
}
.taskRowSub {
  font-size: 12px;
  color: var(--text-secondary);
}

.btnWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.btnWrap button {
  height: 44px;
}

.historyTitle {
  font-size: 14px;
  font-weight: 500;
  margin: 14px 0;
  color: var(--text-secondary);
}
.historyItem {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  gap: 4px;
}

.historyItem .historyName {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
}
.historyLeftSide {
  display: flex;
  gap: 8px;
  align-items: center;
}

.historyRightSide {
  display: flex;
  gap: 8px;
  align-items: center;
}

.historyItem .historyDate {
  color: var(--text-secondary);
}

.historyItem .historyCount {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  font-size: 16px;
  text-align: right;
  font-weight: 600;
}
.historySuccess {
  color: var(--success);
}
.historyDanger {
  color: var(--warning);
}
.historyError {
  color: var(--error);
}
.blockQuote {
  padding: 6px 6px 6px 12px;
  margin: 2px 0;
  border-left: 5px solid var(--iris);
  background-color: #f9f9f9;
  color: #555;
}
