.tabs {
  display: flex;
  margin-bottom: 24px;
  gap: 24px;
}

.tabs.wrap {
  justify-content: space-between;
  align-items: center;
}

.tabs-inner {
  display: flex;
  gap: 24px;
}

.tabs a {
  text-decoration: none;
  color: var(--text);
}

.tabs .tab {
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  opacity: 0.7;
  cursor: pointer;
  display: flex;
}
.tabs svg {
  max-height: 18px;
  align-items: center;
}

.tab.active {
  border-bottom: 3px solid var(--iris);
  opacity: 1;
}

#tabs-right {
  display: flex;
  gap: 8px;
}
