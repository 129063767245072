.header {
  height: 54px;
  display: flex;
  padding: 8px 24px;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .header {
    padding: 8px 16px;
  }
}
