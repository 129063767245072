.group {
  border: 1px solid var(--accent);
  border-radius: 8px;
}
.group .group-items {
  /* display: none; */
  max-height: 0;
  transition: all 0.2s ease;
  overflow: hidden;
}

.group.expanded {
  border: 1px solid var(--iris);
}
.group.expanded .group-items {
  /* display: block; */
  max-height: 900px;
  overflow: auto;
}
.group .title {
  padding: 16px;
}

.group .row {
  border-top: 1px solid var(--accent);
  display: grid;
  height: 52px;
  grid-template-columns: auto 100px;
  align-items: center;
  padding: 0 0 0 16px;
}

.group .row-edit {
  border-left: 1px solid var(--iris10);
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.group input.add-new {
  border: 1px solid var(--iris10);
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--text-secondary);
  width: 300px;
}
.group input.add-new:focus {
  outline-color: var(--iris);
}
