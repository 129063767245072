.project-heading {
  border: 1px solid var(--accent);
  border-radius: 8px;
  padding: 14px 16px 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project-heading .info {
  font-size: 16px;
  gap: 10px;
  display: flex;
  align-items: center;
}
.project-heading .info span + span {
  border-left: 1px dotted var(--text-secondary);
  padding-left: 10px;
}
.project-heading .info .status {
  font-size: 20px;
}

.project-heading .title {
  font-size: 20px;
  font-weight: 500;
}
.project-heading .title span {
  font-size: 16px;
  color: var(--text-secondary);
  margin-left: 10px;
}

.project-heading .bar {
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--success);
}
