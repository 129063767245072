/*

 	Status_Ready    Status = 1
	Status_Started  Status = 2
	Status_Done     Status = 3
	Status_Error    Status = 4
	Status_Stopped  Status = 5
	Status_Archived Status = 6

*/

.statusItem {
  padding: 6px 16px;
  border-radius: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;

  background: var(--iris10);
}
.statusItem.status-2 {
  background: var(--warning);
}

.statusItem.status-3 {
  background: var(--success);
}

.statusItem.status-4 {
  background: var(--error);
}

.statusItem.status-6 {
  color: var(--iris05);
}

.status {
  width: 24px;
  height: 24px;
  color: var(--iris10);
}
.status.status-2 {
  color: var(--warning);
}

.status.status-3 {
  color: var(--success);
}

.status.status-4 {
  color: var(--error);
}

.status.status-6 {
  color: var(--iris05);
}
