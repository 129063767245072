.sidepanel {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 600px;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 999;
  overflow: auto;
}

.sidepanel.open {
  transform: translateX(0);
  box-shadow: 0 4px 60px 0 var(--accent);
}

.closeButton {
  padding: 16px 16px 0;
}

.sidepanel .content {
  padding: 16px;
  flex: 1;
}

.flexCol {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.footer {
  border-top: 1px solid var(--accent);
  margin: 8px;
  padding: 12px 12px 4px;
}