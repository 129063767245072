.projectRow {
  display: grid;
  grid-template-columns: auto 100px;
  gap: 16px;
  margin-bottom: 24px;
}
.projectRow .projectWrap {
  display: grid;
  grid-template-columns: calc(65% - 8px) calc(35% - 8px);
  gap: 16px;
}

.projectRow .projectWrap:last-of-type {
  margin-bottom: 60px;
}

.subprojectRow {
  display: grid;
  grid-template-columns: auto 100px;
  gap: 16px;
  margin-bottom: 24px;
}
.subprojectRow:last-of-type {
  margin-bottom: 60px;
}

.subprojectRow .subprojectWrap {
  display: grid;
  grid-template-columns: 8px calc(65% - 32px) calc(35% - 8px);
  gap: 16px;
}

.subprojectRow .actionRow {
  margin-top: 28px;
  display: flex;
}

.operationGroupRow {
  display: grid;
  grid-template-columns: auto 100px;
  gap: 16px;
}

.operationGroupRow .actionRow {
  margin-top: 60px;
  display: flex;
}

.operationGroupWrap {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 16px;
  padding: 16px;
  border: 1px solid var(--accent);
  margin: 16px 0 16px 48px;
  border-radius: 8px;
}
.operationGroupWrap .tasks {
  display: flex;
  gap: 8px;
  padding: 0 8px 0 0;
}
.operationGroupWrap .tasksCol {
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex-grow: 1;
}
.operationGroupWrap label {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.operationGroupWrap select {
  min-width: 160px;
}

.actions {
  padding-top: 26px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.actions .actionsRow {
  display: flex;
}

.saveAction {
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-end;
  background: #fff;
}
