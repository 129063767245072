.bottomSheet {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  display: flex;
  justify-content: center;
  top: 100%;
  transition: top 0.2s ease;
  z-index: 9;
}
.bottomSheet.open {
  top: 0;
}

.inner {
  max-width: 800px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.inputs {
  margin-top: 10px;
  display: flex;
  gap: 12px;
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 8px;
}
.buttonWrap button {
  width: 100%;
}
