.sidepanel {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 600px;
  background-color: #fff;
  box-shadow: 0 4px 60px 0 var(--accent);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  overflow: auto;
  z-index: 999;
}

.sidepanel.open {
  transform: translateX(0);
}

.sidepanel.small {
  width: 350px;
}

.flexCol {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.close {
  margin-bottom: 16px;
  color: var(--iris);
}
.wrap {
  padding: 24px;
}

.formWrap {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 24px;
}

.subTitle {
  font-size: 24px;
}

.inputWrap {
  display: flex;
  gap: 16px;
  margin: 24px 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.blocks {
  margin: 20px 0;
  display: grid;
  gap: 8px;
  grid-template-columns: 50% 50%;
}

.blocks3 {
  margin: 20px 0;
  display: grid;
  gap: 8px;
  grid-template-columns: 33% 33% 33%;
}

.taskBlock {
  padding: 24px 16px;
  border: 1px solid rgba(236, 241, 244, 1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.taskRowTitle {
  font-size: 20px;
  font-weight: 500;
}
.taskRowSub {
  font-size: 12px;
  color: var(--text-secondary);
}

.tableWrap {
  max-width: 100%;
  overflow: auto;
  padding: 0 1px 5px;
}

.btnRight {
  display: flex;
  justify-content: flex-end;
  margin-top: -60px;
  margin-bottom: 26px;
}
.blockQuote {
  padding: 20px;
  margin: 8px 0;
  border-left: 5px solid var(--iris);
  background-color: #f9f9f9;
  color: #555;
}
