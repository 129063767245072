.root {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.box {
  border: 1px solid var(--accent);
  border-radius: 8px;
  padding: 24px 16px;
  min-width: 400px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.inputWrap input {
  border: 1px solid var(--iris10);
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--text-secondary);
  width: 100%;
}
.inputWrap input:focus {
  outline-color: var(--iris);
}
