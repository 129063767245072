.addBtnWrap {
  display: flex;
  justify-content: flex-end;
}

.addTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: left;
}

.root {
  max-width: 750px;
  margin: 0 auto;
}

.row {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.row input[type="checkbox"] {
  margin-top: -24px;
}

.submit {
  justify-content: flex-end;
}

.label {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}
