.info-block-wrap {
  display: flex;
  gap: 8px;
}

.info-block {
  border: 1px solid var(--accent);
  border-radius: 8px;
  padding: 24px 16px;
  flex-grow: 1;
  line-height: normal;
}
.info-block .title {
  font-size: 36px;
  font-weight: 700;
}

.info-block .sub {
  font-size: 12px;
  color: var(--text-secondary);
}
