.group {
  border: 1px solid var(--accent);
  margin: 12px 0;
  cursor: pointer;
}
.group .title {
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
}

.group .operation {
  border-top: 1px solid var(--accent);
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
.group .operation:hover {
  background: var(--accent);
}

.group .operation-title {
  display: flex;
  gap: 8px;
}

.group .status {
  width: 24px;
  height: 24px;
  color: var(--iris10);
}
.group .status.status-2 {
  color: var(--warning);
}
.group .status.status-3 {
  color: var(--success);
}

.group .status.status-4 {
  color: var(--error);
}
