.inputWrap {
  width: 100%;
}

.inputWrap label {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputWrap input,
.inputWrap textarea,
.inputWrap select {
  border: 1px solid var(--iris10);
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--text-secondary);
  width: 100%;
  font-size: 13.3333px;
  line-height: normal;
  font-family: arial;
}
.inputWrap input:focus,
.inputWrap select:focus {
  outline-color: var(--iris);
}

.inputWrap input.error,
.inputWrap select.error {
  border: 1px solid var(--error);
}

label.checkbox {
  flex-direction: row;
  justify-content: flex-start;
}

.checkbox input {
  width: auto;
}
