.breadcrumbs {
  margin: 24px 0;
  font-size: 14px;
  color: var(--text-secondary);
  font-weight: 500;
}
.breadcrumbs a {
  color: var(--text-secondary);
  text-decoration: none;
}
.breadcrumbs a:last-of-type {
  color: var(--text);
  pointer-events: none;
}

.breadcrumbs .split {
  display: inline-block;
  margin: 0 8px;
}
