.title {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  margin-bottom: 20px;
  gap: 16px;
  align-items: center;
}
.title a {
  height: 24px;
}

span.error {
  color: var(--error);
}

.tabsWrap {
  overflow: auto;
  max-width: 100%;
}
