.wrap .title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.wrap .heading {
  font-size: 14px;
  color: var(--text-secondary);
}
